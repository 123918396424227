<template>
  <div class="mt-4">
    <div class="row full-banner-tag-filter bg-white pt-3 editions-wrap">
      <div class="col text-center ml-sm-5 mr-sm-5">
        <b-form-input v-model="filter"
                      placeholder="Search by artist or ETH address">
        </b-form-input>
      </div>
    </div>

    <div class="row mb-4 editions-wrap">
      <div v-for="artist in filteredList" :key="artist.name" class="col-12 col-sm-4">
        <router-link :to="{ name: 'artist', params: { artistAccount: getArtistAddress(artist) } }">
          <simple-artist-panel :artist="artist"></simple-artist-panel>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import Artist from '../components/cards/Artist';
  import SimpleArtistPanel from '../components/artist/SimpleArtistPanel';
  import * as _ from 'lodash';
  import { BFormInput } from 'bootstrap-vue';

  export default {
    name: 'artists',
    components: {
      Artist, 
      SimpleArtistPanel,
      ...{BFormInput}
    },
    data() {
      return {
        filter: null
      };
    },
    computed: {
      ...mapGetters([
        'liveArtists',
      ]),
      filteredList() {
        if (!this.filter) {
          return _.shuffle(this.liveArtists);
        }
        return this.liveArtists.filter(post => {
          const nameContains = post.name.toLowerCase().includes(this.filter.toLowerCase());
          const ethAddressContains = post.ethAddress[0].toLowerCase().includes(this.filter.toLowerCase());
          return nameContains || ethAddressContains;
        });
      },
    },
    mounted() {

    },
    methods: {
      getArtistAddress(artist) {
        if (_.isArray(artist.ethAddress)) {
          return artist.ethAddress[0];
        }
        return artist.ethAddress;
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../ko-colours.scss';

  a {
    text-decoration: none;
  }

  input[type="text"],
  select.form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid $realBlack;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }

  input[type="text"]:focus,
  select.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>
