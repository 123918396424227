<template>
  <span class="artist-name-lg">
    <span v-if="resolved">
        {{ resolved }}
    </span>
    <span v-else>
        {{ value | dotDotDot }}
    </span>
  </span>
</template>

<script>
  import * as actions from '../../store/actions';

  export default {
    name: 'eth-address',
    props: ['value', 'resolve'],
    data() {
      return {
        resolved: false
      };
    },
    created() {
      // Attempt to find ENS name
      if (this.resolve) {
        this.$store.dispatch(`${actions.REVERSE_RESOLVE_ENS_NAME}`, this.value)
          .then((ensName) => {
            this.resolved = ensName;

            // Fall back to username
            if (!ensName) {
              this.$store.dispatch(`${actions.REVERSE_RESOLVE_USER_NAME}`, this.value)
                .then((username) => {
                  this.resolved = username;
                })
                .catch((err) => {
                  console.log('Unable to find Username', this.value, err);
                });
            }
          })
          .catch((err) => {
            console.log('Unable to find ENS name', this.value, err);
          });
      }
    }
  };
</script>

<style scoped>

</style>
