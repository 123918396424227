<template>
  <span v-if="ethAddress">
    <a class="artist-link text-decoration-none" :href="buildLink" :target="keepInternal ? '_self' : '_blank'">
      <eth-address :value="ethAddress" :resolve="resolve"></eth-address>
    </a>
  </span>
</template>

<script>
  /* global web3:true */
  import {mapState} from 'vuex';
  import EthAddress from './EthAddress';

  export default {
    name: 'clickableAddress',
    components: {EthAddress},
    props: {
      ethAddress: {
        type: String
      },
      keepInternal: {
        type: Boolean,
        default: true
      },
      resolve: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState([
        'etherscanBase',
      ]),
      buildLink: function () {
        if (this.keepInternal) {
          return `/account/${this.ethAddress}`;
        }
        return `${this.etherscanBase}/address/${this.ethAddress}`;
      }
    }
  };
</script>
